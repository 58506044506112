import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const VolumizerHairAdditionsWomen = () => {

  const seoData = {
    title: 'Volumizer Hair Additions for Women in Delhi - Radiance ',
    description: 'To know more about volumizer hair additions for women in Delhi go through our guide article. This will lead you to volumizer hair adding solutions with Radiance',
    keywords: ['Volumizer Hair Additions for Women in Delhi'],
  };
  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Service Page",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png"
    ],
    "category": "Volumizer Hair Additions",
    "additionalType": "https://www.radiancehairstudio.com/volumizer-hair-additions-for-women.php",
    "description": "To know more about volumizer hair additions for women in Delhi, go through our guide article. This will lead you to volumizer hair adding solutions with Radiance.",
    "disambiguatingDescription": "Volumizer Hair Additions for Women in Delhi",
    "serviceType": "Volumizer Hair Additions for Women"
  };

  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
        img: "/volumizer-hair-additions-for-women.png",
        content: (
            <div>
                <h2>Volumizer Hair Additions</h2>
                <p>
                How many times have you dreamt of having those VOLUMINOUS LOCKS but ended up with mere shafts? Your lifestyle and eating habits restricted you from having the same. Ok!However, we are here to offer a fantastic method to make that MANE appear incredibly voluminous, not to start a blame game.. Volumizing products? No! This time you guessed it WRONG! An even better hair saver has been developed by Radiance!! THE HAIR ADDITIONS. 
                </p>
                <p>
                Volumizing products? No! This time you guessed it WRONG! An even better hair saver has been developed by Radiance! THE ADDITIONS FOR HAIR.
                </p>
                <p>
                Hair loss has been a growing problem for women worldwide. When new hair follicles do not sprout in the same location, it is cause for concern. We refer to the inadequate hair development as THINNING!! HAIR VOLUMIZERS are the best that one can have to fight the situation!We use a 100% natural human hair volumiser at <a href="/" style={{ color: "#373737"}}> Radiance Hair Studio</a> to give the LIMP LOCKS more volume! We encountered women with the flat, straight falling mane that lacked CHARACTER too!The lovely character we already possess is accentuated by our hair. Thinning hair or the scanty hair shafts on our scalp should never be a reason for the FALL IN CONFIDENCE. Our goal at RADIANCE is to clip the human hair volumiser to the existing hair roots in the same way. Not every solution involves SURGERY! There is much more to be surprised by in the modern beauty industry. You would be thrilled to adopt HAIR VOLUMISERS! 

                </p>
            </div>
        )
    }
];

const sectionS3 = [
    {
        smallImg: "/volumizer-hair-additions-for-women1.png",
        largeImg: "/volumizer-hair-additions-for-women2.png",
        content: (
            <div>
                <h2>Benefits: The Beauty!</h2>
                <p>Purchasing a high-quality human hair volumiser to show off your gorgeous hair can potentially break the bank. There should be reasons enough to make the most out of the investment you indulge in. These are a few of the attractive advantages that will persuade you to follow suit. After all, WE ARE WORTH IT! </p>
                <ul>
                    <li>
                    Our skilled experts meticulously attach <a href="/hair-extensions.php" style={{ color: "#373737"}}>hair additions</a> to the roots of your natural hair, ensuring a seamless blend that enhances volume and boosts your confidence.
                    </li>
                    <li>
                      A human hair volumizer feels just like your own hair, allowing you to style it effortlessly. It makes hairstyling easier, solving the challenges that come with thin, lifeless strands.
                    </li>
                    <li>This volumizer offers an instant transformation, enhancing the texture, color, length, and overall fullness of your hair—truly reflecting your inner strength and personality. BORN CONFIDENT!
                    </li>
                    <li>
                    Say goodbye to limp locks! With 100% natural human hair, you can enjoy thick, luscious, and stunning tresses. OWN YOUR LOOK, QUEEN!
                    </li>
                </ul>
                <p>Confidence is all you need to make a mark of your own! All the confidence that was gone with the falling hair gets back to shape with Radiance Hair Studio. At RADIANCE, we create the best human hair volumisers for people who want the best for themselves.. QUEENS should always own a PRICELESS CROWN!</p>
            </div>
        )
    }
];

const sectionS4 = [
    {
        title: "FAQs: Your LOCKS UNLOCKED!",
        description: "We hear your concern at RADIANCE. We understand the queries that need to be answered before you step forward to get your additional hair volumizer. Now that RADIANCE has your back, you can finally get the answers to the issues that have been plaguing you! ",
        description2: "We hope we’ve answered all your queries. Everybody is entitled to the finest hair care. At RADIANCE, our experts offer a free consultation to help you find the perfect solution for your hair needs!",
        faq: [
            {
                title: "Why choose a hair volumizer over volumizing products?",
                description: "Both volumizing products and additional hair volumizers serve different purposes, and the choice depends on personal preference. However, a hair volumizer provides a long-term and more effective solution for those dealing with hair thinning, unlike temporary volumizing products."
            },
            {
                title: "What is the difference between a hair extension and a hair volumizer?",
                description: "Hair extensions primarily add length to your existing hair, while a hair volumizer enhances thickness by adding extra hair directly to the scalp, creating a fuller and more voluminous look."
            },
            {
                title: "How long does a hair volumizer last?",
                description: "With proper care and maintenance, a high-quality human hair volumizer can last for up to a year, ensuring long-lasting results."
            },
            {
                title: "Does a hair volumizer damage natural hair?",
                description: "No, a hair volumizer is carefully hand-tied to the roots without the use of harsh chemicals or adhesives, ensuring that your scalp and natural hair remain unharmed."
            },
            {
                title: "Can I style my hair after getting a volumizer?",
                description: "Yes, you can style your hair just like you would with natural hair—whether it’s coloring, curling, or straightening. However, excessive heat or chemical treatments should be avoided to maintain its quality."
            }
        ],
    }
];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Volumizer Hair Additions Women's" banner="/volumizer-hair-additions.png" />
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Volumizer Hair Additions" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default VolumizerHairAdditionsWomen;
